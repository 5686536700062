<template>
  <div class="map">
    <div class="map__controls">
      <button class="button" v-if="!nexttrip" @click="showNextTrip">Toon eerstvolgende rit</button>
      <button class="button" v-if="nexttrip" @click="showAllMarkers">Terug naar overzicht</button>
      <button class="button" v-if="trackAvailable" @click="toggleTrace">
        {{ isTracking ? 'Stop tracking' : 'Track chauffeur' }}
      </button>
      <button class="button button--disabled" v-else-if="nexttrip" title="Nog geen tracking data beschikbaar">
        Track chauffeur
      </button>
    </div>
    <div class="map__info" v-if="nexttrip">
      <div>Tijd: <b>{{`${nexttrip.uur}, ${nexttrip.displaydatum}`}}</b></div>
      <div>Traject: <b>{{ nexttrip.richting === 'H' ? `${activeP.naam} => ${activeC.naam}` : `${activeC.naam} => ${activeP.naam}` }}</b></div>
      <div>Bus nr: <b>{{nexttrip && nexttrip.idwagen && nexttrip.idwagen != "0"?nexttrip.idwagen:'Nog onbekend'}}</b></div>
    </div>
    <div class="map__info" v-else>Klik op een punt om meer informatie te verkrijgen of vraag uw eerstvolgende rit op.</div>
    <div class="map__container" :class="{'map__container--small':nexttrip}" id="map"></div>
  </div>
</template>

<script>
export default {
  name: "Map",

  data() {
    return {
      activeC: null,
      activeP: null,
      nexttrip: null,
      tripRoute: null,
      directionsRenderer: null,
      directionsService: null,
      map: null,
      openwin: [],
      markers: [],
      isTracking: false,
      trackTimeout: null
    }
  },

  created(){
    document.addEventListener('visibilitychange', this.visibilityChanged);
  },
  mounted() {
    this.initMap();
  },

  beforeUnmount() {
    clearTimeout(this.trackTimeout);
    this.markers.forEach(m => {
      m.setMap(null);
      m = null;
    });
    document.removeEventListener('visibilitychange',this.visibilityChanged);
  },
  computed: {
    pLocations() {
      return this.$store.getters['locations/pickupLocations'];
    },
    hasPLocations() {
      return this.$store.getters['locations/hasPickupLocations'];
    },
    cLocations() {
      return this.$store.getters['locations/corporateLocations'];
    },
    hasCLocations() {
      return this.$store.getters['locations/hasCorporateLocations'];
    },
    trackAvailable(){
      const nt = this.nexttrip;
      return nt && nt.tracking_id && nt.idwagen && nt.idwagen != '0';
    }
  },

  methods: {
    initMap() {
      this.directionsService = new google.maps.DirectionsService();
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: {lat: 51.21848884674257, lng: 4.4053227982318175},
        zoom: 10,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });

      this.moveToCurrentLocation();
      this.drawAllMarkers();
    },

    moveToCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const devCenter = new google.maps.LatLng(lat, lng);
          this.map.setCenter(devCenter);
        });
      }
    },

    clearMarkers() {
      this.closeWindows();
      this.markers.forEach(m => {
        m.setVisible(false)
      });
    },

    closeWindows() {
      this.openwin.forEach(o => {
        o.close()
      });
    },

    showAllMarkers() {
      this.nexttrip = null;
      this.activeP = null;
      this.activeC = null;
      this.clearRoute();
      this.closeWindows();
      if(this.isTracking){
        this.toggleTrace();
      }
      this.markers.forEach(m => {
        if (m.id !== 'chauffeur') {
          m.setVisible(true)
        }
      });
      this.map.setZoom(10);
      this.moveToCurrentLocation();
    },

    displayRouteMarkers(p, c) {
      this.closeWindows();
      this.markers.forEach(m => {
        m.setVisible(parseInt(m.id) === parseInt(p) || parseInt(m.id) === parseInt(c));
      });
    },

    drawAllMarkers() {
      const plocs = this.pLocations;
      const clocs = this.cLocations;
      const svgMarkerP = {
        path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
        fillColor: "#FAD701",
        strokeColor: "black",
        fillOpacity: 0.8,
        strokeWeight: 2,
        rotation: 0,
        scale: 0.8
      };
      const svgMarkerC = {
        path:
            "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
        fillColor: "black",
        strokeColor: "#FAD701",
        fillOpacity: 0.8,
        strokeWeight: 2,
        rotation: 0,
        scale: 0.8
      };
      plocs.forEach(p => {
        const latlng = {lat: parseFloat(p.lat), lng: parseFloat(p.lon)};
        const marker = new google.maps.Marker({
          position: latlng,
          title: p.naam,
          icon: svgMarkerP,
          map: this.map
        });
        marker.set("id", p.id);
        const contentString = `<p><b>${p.naam} (P)</b></p><p>${p.straat} ${p.nummer}<br>${p.postnr} ${p.stad}</p><p><b>Opstaptijden:</b><br>${p.urenHeen}</p>`;
        const infowindow = new google.maps.InfoWindow({
          content: contentString,
        });
        marker.addListener("click", () => {
          infowindow.open(this.map, marker);
          this.openwin.push(infowindow)
        });
        this.markers.push(marker);
      });
      clocs.forEach(c => {
        const latlng = {lat: parseFloat(c.lat), lng: parseFloat(c.lon)};
        const marker = new google.maps.Marker({
          position: latlng,
          title: c.naam,
          icon: svgMarkerC,
          map: this.map
        });
        marker.set("id", c.id);
        const contentString = `<p><b>${c.naam} (C)</b></p><p>${c.straat} ${c.nummer}<br>${c.postnr} ${c.stad}</p><p><b>Opstaptijden:</b><br>${c.urenTerug}</p>`;
        const infowindow = new google.maps.InfoWindow({
          content: contentString,
        });
        marker.addListener("click", () => {
          infowindow.open(this.map, marker);
          this.openwin.push(infowindow);
        });
        this.markers.push(marker);
      });
    },

    async showNextTrip() {
      try {
        this.nexttrip = await this.$store.dispatch('planner/nextTrip');
        this.showNextTripData();
      } catch (e) {
        alert('Nog geen ritdata beschikbaar.');
      }
    },
    showNextTripData() {
      const nt = this.nexttrip;
      if (nt) {
        this.displayRouteMarkers(nt.billo2work_loc_id, nt.billo2work_corp_id);
        this.drawRoute(nt.billo2work_loc_id, nt.billo2work_corp_id, nt.richting)
      }
    },

    clearRoute() {
      if (this.directionsRenderer != null) {
        this.directionsRenderer.setMap(null);
        this.directionsRenderer = null;
      }
    },

    drawRoute(pickup, corp, dir) {
      //this.directionsRenderer = new google.maps.DirectionsRenderer();
      //this.directionsRenderer.setOptions({suppressMarkers: true});
      //this.directionsRenderer.setMap(this.map);
      const p = this.pLocations.find(l => l.id == pickup);
      const c = this.cLocations.find(l => l.id == corp);
      this.activeP = p;
      this.activeC = c;
      const latlngP = {lat: parseFloat(p.lat), lng: parseFloat(p.lon)};
      const latlngC = {lat: parseFloat(c.lat), lng: parseFloat(c.lon)};

      const origin = dir === 'H' ? latlngP : latlngC;
      //const destination = dir === 'H' ? latlngC : latlngP;
      //const request = {
      //  origin,
      //  destination,
      //  travelMode: 'DRIVING'
      //};
      //this.directionsService.route(request, (result, status) => {
      //  if (status == 'OK') {
      //    this.directionsRenderer.setDirections(result);
      //  }
      //});
      this.map.panTo(origin);
    },
    toggleTrace() {
      this.isTracking = !this.isTracking;
      let trackId = this.nexttrip?.tracking_id;
      if (this.isTracking && trackId) {
        this.trackC(trackId);
      }
      else if (!this.isTracking) {
        const chauffeur = this.markers.find(m => m.id === 'chauffeur');
        if (chauffeur) {
          chauffeur.setVisible(false);
        }
        clearTimeout(this.trackTimeout);
        console.log('stopped tracking chauffeur');
      } else {
        alert('Nog geen chauffeur-data beschikbaar.');
        this.isTracking = false;
      }
    },
    async trackC(trackId) {
      if (!this.isTracking || !trackId) {
        return;
      }
      try {
        const trackdata = await this.$store.dispatch('planner/trackChauffeur', {id: trackId})
        this.drawC(trackdata);
        this.trackTimeout = setTimeout(() => {
          this.trackC(trackId);
        }, 30*1000);
      } catch (e) {
        alert(e);
        this.toggleTrace();
      }
    },
    drawC(trackdata) {
      if(!this.isTracking){
        return;
      }
      const chauffeur = this.markers.find(m => m.id === 'chauffeur');
      const latlng = {lat: parseFloat(trackdata.lat), lng: parseFloat(trackdata.lon)};
      if (!chauffeur) {
        const svgMarkerC = {
          path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
          fillColor: "red",
          strokeColor: "black",
          fillOpacity: 0.8,
          strokeWeight: 2,
          rotation: 0,
          scale: 0.8
        };
        const marker = new google.maps.Marker({
          position: latlng,
          title: 'Chauffeur',
          icon: svgMarkerC,
          map: this.map
        });
        marker.set("id", 'chauffeur');
        const contentString = `<p><b>Bus </b></p>`;
        const infowindow = new google.maps.InfoWindow({
          content: contentString,
        });
        marker.addListener("click", () => {
          infowindow.open(this.map, marker);
          this.openwin.push(infowindow)
        });
        this.markers.push(marker);
        marker.setMap(this.map);
      } else {
        chauffeur.setPosition(latlng);
        chauffeur.setVisible(true);
      }
      this.map.panTo(latlng);
    },

    visibilityChanged(){
      if (document.visibilityState === 'visible') {
        console.log('map visibility state changed.');
        if(this.isTracking){
          this.toggleTrace();
        }
        if(this.nexttrip){
          this.showAllMarkers();
        }
      }
    }
  }
}
</script>