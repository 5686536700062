export default {
    setUserData(state, payload){
        state.userData = payload;
    },
    setCompanyData(state, payload){
        state.companyData = payload;
    },
    setCalendarData(state, payload){
        state.calendarData = payload;
    }
}