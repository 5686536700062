<template>
  <div class="page planner">
    <h1>Plan uw ritten</h1>
    <div class="planner__content">
      <div class="loader__container" v-if="isLoading">
        <fa class="loader" icon="spinner"></fa>
      </div>
      <div class="planner__calendar">
        <div class="planner__calendar__controls" v-if="calendarData.meta">
          <label class="checkbox checkbox--small">
            <span class="checkbox__label">Selecteer meerdere dagen</span>
            <input type="checkbox" v-model="isMultiSelect">
            <span class="checkbox__checkmark"></span>
          </label>

        </div>
        <div class="planner__calendar__controls__date planner__calendar__controls__date--nav">
          <button class="svgbutton" @click="prevMonth"><fa icon="chevron-left"></fa></button>
          <div>
            <span>{{months[currentMonth-1]}}</span>
            <span>{{currentYear}}</span>
          </div>
          <button class="svgbutton" @click="nextMonth"><fa icon="chevron-right"></fa></button>
        </div>
        <!--<div class="planner__calendar__controls__date">
          <select class="select" v-model="currentMonth">
            <option :value="item" v-for="item in 12" :key="`month--${item}`">{{ months[item-1] }}</option>
          </select>
          <select class="select" v-model="currentYear">
            <option :value="todayYear - 1">{{ todayYear - 1 }}</option>
            <option :value="todayYear">{{ todayYear }}</option>
            <option :value="todayYear + 1">{{ todayYear + 1 }}</option>
          </select>
        </div>-->
        <div class="planner__calendar__container">
          <template v-if="calendarData && calendarData.data && calendarData.data.length">
            <div class="planner__calendar__day planner__calendar__day--empty" v-for="item in calendarData.data[0].dagnr - 1" :key="`empty--${item}`">

            </div>
          </template>
          <div class="planner__calendar__day"
               :class="{'planner__calendar__day--H':item.bookings.find(i => i.richting === 'H'),
               'planner__calendar__day--T':item.bookings.find(i => i.richting === 'T'),
              'planner__calendar__day--today':item.datum === today,
              'planner__calendar__day--active':(activeItem && item.datum === activeItem.datum)||multiArray.indexOf(item.datum)>-1,
              'planner__calendar__day--past':checkPast(item.datum)}"
               v-for="item in calendarData.data" :key="item.datum" @click="editDatum(item)">
            <p class="planner__calendar__day__name">{{ days[item.dagnr - 1].substr(0, 2) }}</p>
            <p class="planner__calendar__day__number"><b>{{ item.datum.substr(0, 2) }}</b></p>
          </div>
        </div>
      </div>
      <div class="planner__form" id="planform">
        <template v-if="activeItem || multiArray.length">
          <h2 v-if="activeItem">{{ `${longDays[activeItem.dagnr - 1]} ${activeItem.datum}` }}</h2>
          <h2 v-else>Meedere datums</h2>
          <form action="" class="form" @submit.prevent="">
            <div class="form__column">
              <h3>Heen</h3>
              <div class="form__field">
                <label for="ploc">Ophaalpunt</label>
                <select name="ploc" id="ploc" class="select" v-model="order.location_id_heen"
                        :disabled="(isEdit && isHeen) || (activeItem && checkPast(activeItem.datum))">
                  <option v-for="item in pickupLocations" :value="item.id" :key="`ploc--${item.id}`">
                    {{item.naam}} -- {{item.stad}}
                  </option>
                </select>
                <button class="svgbutton" :class="{'svgbutton--disabled':(isEdit && isHeen) || (activeItem && checkPast(activeItem.datum))}" @click.prevent="showMap('location_id_heen')"><fa icon="map"></fa></button>
              </div>
              <div class="form__field">
                <label for="cloc">Bestemming</label>
                <select name="cloc" id="cloc" class="select" v-model="order.corp_id_heen"
                        :disabled="(isEdit && isHeen) || (activeItem && checkPast(activeItem.datum))">
                  <option v-for="item in corporateLocations" :value="item.id" :key="`cloc--${item.id}`">{{
                      item.naam
                    }}
                  </option>
                </select>
                <button class="svgbutton" :class="{'svgbutton--disabled':(isEdit && isHeen) || (activeItem && checkPast(activeItem.datum))}" @click.prevent="showMap('corp_id_heen')"><fa icon="map"></fa></button>
              </div>
              <div class="form__field">
                <label for="ptime">Ophaaltijd</label>
                <select name="ptime" id="ptime" class="select" v-model="order.uurHeen"
                        :disabled="(isEdit && isHeen) || (activeItem && checkPast(activeItem.datum))">
                  <option v-for="item in pickupTimes" :value="item" :key="`ptime--${item}`">{{ item }}</option>
                </select>
              </div>
            </div>
            <div class="form__column">
              <h3>Terug</h3>
              <div class="form__field">
                <label for="cloct">Bedrijfslocatie</label>
                <select name="cloct" id="cloct" class="select" v-model="order.corp_id_terug"
                        :disabled="(isEdit && isTerug) || (activeItem && checkPast(activeItem.datum))">
                  <option v-for="item in corporateLocations" :value="item.id" :key="`cloc--${item.id}`">{{
                      item.naam
                    }}
                  </option>
                </select>
                <button class="svgbutton" :class="{'svgbutton--disabled':(isEdit && isTerug) || (activeItem && checkPast(activeItem.datum))}" @click.prevent="showMap('corp_id_terug')"><fa icon="map"></fa></button>
              </div>
              <div class="form__field">
                <label for="ploct">Bestemming</label>
                <select name="ploct" id="ploct" class="select" v-model="order.location_id_terug"
                        :disabled="(isEdit && isTerug) || (activeItem && checkPast(activeItem.datum))">
                  <option v-for="item in pickupLocations" :value="item.id" :key="`ploc--${item.id}`">{{item.naam}} -- {{
                      item.stad
                    }}
                  </option>
                </select>
                <button class="svgbutton" :class="{'svgbutton--disabled':(isEdit && isTerug) || (activeItem && checkPast(activeItem.datum))}" @click.prevent="showMap('location_id_terug')"><fa icon="map"></fa></button>
              </div>
              <div class="form__field">
                <label for="ctime">Vertrektijd</label>
                <select name="ctime" id="ctime" class="select" v-model="order.uurTerug"
                        :disabled="(isEdit && isTerug) || (activeItem && checkPast(activeItem.datum))">
                  <option v-for="item in dropoffTimes" :value="item" :key="`ctime--${item}`">{{ item }}</option>
                </select>
              </div>
            </div>
            <div class="form_field form__field--controls" v-if="!(activeItem && checkPast(activeItem.datum))">
              <button class="button button--accept" @click.prevent="submitOrder('H')" v-if="!(isEdit && isHeen)"
                      :class="{'button--disabled':isLoading}">
                Boek Heen
              </button>
              <button v-if="isEdit && isHeen" class="button button--decline" @click.prevent="cancelOrder('H')"
                      :class="{'button--disabled':isLoading || activeRitH.read_only}">Annuleer Heen
              </button>
              <button class="button button--accept" @click.prevent="submitOrder('T')" v-if="!(isEdit && isTerug)"
                      :class="{'button--disabled':isLoading}">
                Boek Terug
              </button>
              <button v-if="isEdit && isTerug" class="button button--decline" @click.prevent="cancelOrder('T')"
                      :class="{'button--disabled':isLoading || activeRitT.read_only}">Annuleer Terug
              </button>
              <button class="button button--accept" @click.prevent="submitOrder(false)"
                      v-if="!(isEdit && (isHeen || isTerug))"
                      :class="{'button--disabled':isLoading}">
                Boek H & T
              </button>
              <button v-if="isEdit && isHeen && isTerug" class="button button--decline" @click.prevent="cancelOrder(false)"
                      :class="{'button--disabled':isLoading || activeRitT.read_only}">Annuleer H & T
              </button>
            </div>
            <p class="error" v-if="error">{{ error }}</p>
          </form>
        </template>
        <template v-else><p>Duid een datum aan op de kalender om boekingen aan te maken of bestaande boekingen te bekijken.</p></template>
      </div>
    </div>
    <teleport to="#app">
      <div class="modal" v-if="mapData">
        <div class="modal__wrapper">
          <button class="svgbutton modal__close error" @click="mapData=null">
            <fa icon="times"></fa>
          </button>
          <div class="modal__body">
            <SelectMap :data="mapData.locations" :selected="mapData.selected" @on-id-chosen="setDefaultMarker"/>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import SelectMap from "@/components/SelectMap";
export default {
  name: "Planner",
  components:{SelectMap},
  data() {
    return {
      init: true,
      today: null,
      todayYear: 0,
      currentMonth: 0,
      currentYear: 0,
      error: null,
      isLoading: false,
      activeItem: null,
      order: null,
      isHeen: true,
      isTerug: true,
      isEdit: false,
      isMultiSelect: false,
      multiArray: [],
      initLoad: true,
      mapData: null,
      days:['Ma','Di','Wo','Do','Vr','Za','Zo'],
      longDays:['Maandag','dinsdag','Woensdag','Donderdag','Vrijdag','Zaterdag','Zondag'],
      months:['Januari','Februari','Maart','April','Mei','Juni','Juli','Augustus','September','Oktober','November','December']
    }
  },
  created() {
    this.getToday();
    this.loadCalendarData();
    document.addEventListener('visibilitychange', this.visibilityChanged);
  },
  beforeUnmount() {
    document.removeEventListener('visibilitychange',this.visibilityChanged);
  },
  computed: {
    calendarData() {
      return this.$store.getters['planner/calendarData'];
    },
    userData() {
      return this.$store.getters['planner/userData'];
    },
    pickupLocations() {
      const locs = [...this.$store.getters['locations/pickupLocations']];
      return locs.sort((a,b) => (a.stad.toLowerCase() > b.stad.toLowerCase()) ? 1 : ((b.stad.toLowerCase() > a.stad.toLowerCase()) ? -1 : 0));
    },
    corporateLocations() {
      return this.$store.getters['locations/corporateLocations'];
    },
    pickupTimes() {
      if (!this.order) {
        return [];
      }
      const p = this.pickupLocations.find(l => parseInt(l.id) === parseInt(this.order.location_id_heen));
      return p ? p.urenHeen : [];
    },
    dropoffTimes() {
      if (!this.order) {
        return [];
      }
      const d = this.corporateLocations.find(l => parseInt(l.id) === parseInt(this.order.corp_id_terug));
      const p = this.pickupLocations.find(l => parseInt(l.id) === parseInt(this.order.location_id_terug));
      return d && p ? d.urenTerug.filter(u => p.urenTerug.indexOf(u)>-1) : [];
    },

    activeRitH() {
      const ritH = this.activeItem.bookings.find(i => i.richting === "H");
      return ritH ? ritH : null;
    },
    activeRitT() {
      const ritT = this.activeItem.bookings.find(i => i.richting === "T");
      return ritT ? ritT : null;
    }
  },
  watch: {
    pickupTimes() {
      if (this.pickupTimes && !this.isEdit && this.order && this.pickupTimes.indexOf(this.order.uurHeen) < 0) {
        this.order.uurHeen = this.pickupTimes[0];
      }
    },
    dropoffTimes() {
      if (this.dropoffTimes && !this.isEdit && this.order && this.dropoffTimes.indexOf(this.order.uurTerug) < 0) {
        this.order.uurTerug = this.dropoffTimes[0];
      }
    },
    isMultiSelect() {
      if (!this.isMultiSelect) {
        this.multiArray = [];
      } else {
        this.isEdit = false;
        this.order = null;
        this.activeItem = null;
      }
    },
    currentYear() {
      this.clearActive();
      if (!this.initLoad) {
        this.loadCalendarData();
      }
    },
    currentMonth() {
      this.clearActive();
      if (!this.initLoad) {
        this.loadCalendarData();
      }
    }
  },
  methods: {
    clearActive() {
      if (!this.isMultiSelect) {
        this.isEdit = false;
        this.order = null;
        this.activeItem = null;
      }
    },
    getToday() {
      const today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      const yyyy = today.getFullYear();
      this.todayYear = yyyy;
      this.currentMonth = mm;
      this.currentYear = yyyy;
      if (dd < 10) {
        dd = '0' + dd;
      }
      if (mm < 10) {
        mm = '0' + mm;
      }
      this.today = `${dd}/${mm}/${yyyy}`;
    },
    checkPast(datum) {
      //LAUNCH DATE 31/05/2021
      const startDate = new Date('2021/05/31');
      const endDate = new Date('2021/08/31');
      const today = new Date();
      const hours = today.getHours();
      //Booking possible until 18:00 day before
      if(hours >= 18){
        today.setDate(today.getDate() + 1);
      }
      today.setHours(0, 0, 0, 0);
      const dateparts = datum.split('/');
      const date = new Date(`${dateparts[1]}/${dateparts[0]}/${dateparts[2]}`);
      date.setHours(0, 0, 0, 0);
      const isPast = today >= date || date < startDate || date > endDate;
      return isPast;
    },
    async loadCalendarData() {
      if (!this.currentYear || !this.currentMonth) {
        return;
      }
      this.error = null;
      this.isLoading = true;
      try {
        await this.$store.dispatch('planner/loadCalendarData', {month: this.currentMonth, year: this.currentYear});
        this.initLoad = false;
      } catch (error) {
        this.error = error;
      }
      this.isLoading = false;
    },
    editDatum(item) {
      if (this.isMultiSelect) {
        this.addMulti(item);
        return;
      }

      const ud = this.userData;
      this.activeItem = item;
      let richting = "HT"
      this.isEdit = false;
      if (this.activeItem.bookings.length) {
        const ritH = item.bookings.find(i => i.richting === "H");
        const ritT = item.bookings.find(i => i.richting === "T");
        this.isEdit = true;
        this.isHeen = !!ritH;
        this.isTerug = !!ritT;
        richting = `${this.isHeen ? 'H' : ''}${this.isTerug ? 'T' : ''}`;
        const ah = this.activeRitH;
        const at = this.activeRitT;

        let hUur = "";
        let tUur = "";
        if (!this.isHeen) {
          if (at.billo2work_loc_id == ud.location_id) {
            hUur = ud.uurHeen;
          } else {
            const loc = this.pickupLocations.find(l => l.id == at.billo2work_loc_id);
            if (loc) {
              hUur = loc.urenHeen[0];
            }
          }
        } else if (!this.isTerug) {
          if (ah.billo2work_corp_id == ud.corp_id) {
            tUur = ud.uurTerug;
          } else {
            const loc = this.corporateLocations.find(l => l.id == at.billo2work_corp_id);
            if (loc) {
              tUur = loc.urenTerug[0];
            }
          }
        }


        this.order = {
          richting,
          location_id_heen: this.isHeen ? ah.billo2work_loc_id : ud.location_id,
          location_id_terug: this.isTerug ? at.billo2work_loc_id : ud.location_id,
          corp_id_heen: this.isHeen ? ah.billo2work_corp_id : ud.corp_id,
          corp_id_terug: this.isTerug ? at.billo2work_corp_id : ud.corp_id,
          datums: this.isHeen ? [ah.displaydatum] : [at.displaydatum],
          uurHeen: this.isHeen ? ah.uur : hUur,
          uurTerug: this.isTerug ? at.uur : tUur,
          user_id: ud.id
        }
      } else {
        richting = "HT";
        this.isHeen = true;
        this.isTerug = true;
        this.order = {
          richting,
          datums: [this.activeItem.datum],
          location_id_heen: ud.location_id,
          location_id_terug: ud.location_id,
          corp_id_heen: ud.corp_id,
          corp_id_terug: ud.corp_id,
          uurHeen: ud.uurHeen,
          uurTerug: ud.uurTerug,
          user_id: ud.id
        }
      }
      const form = document.getElementById("planform");
      if (form) {
        this.$nextTick(() => {
          form.scrollIntoView({alignToTop: true, behavior: 'smooth'});
        });

      }

    },

    async submitOrder(richting) {
      const data = {...this.order};
      if (!richting) {
        data.richting = 'HT'
      } else {
        data.richting = richting;
      }
      if(data.datums.some(d => this.checkPast(d))){
        alert('1 of meerdere datums die u selecteerde zijn reeds opgenomen in de planning en dus niet langer te wijzigen.\nNeem contact op met de dispatch indien u toch nog een wijziging wenst door te voeren.');
        return;
      }
      data.datums = this.formatDateArraySql(data.datums);
      if (Object.keys(this.order).some(key => !this.order[key])) {
        this.error = 'invalid order';
        return;
      }
      this.isLoading = true;
      try {
        await this.$store.dispatch('planner/newOrder', data);
        this.activeItem = null;
        this.order = null;
        this.isMultiSelect = false;
        this.multiArray = [];
        this.loadCalendarData(this.calendarData.meta.current_month, this.calendarData.meta.current_year);
      } catch (e) {
        this.error = e;
        this.isLoading = false;
      }

    },

    async cancelOrder(richting) {
      if (richting) {
        const rit = this.activeItem.bookings.find(i => i.richting === richting);
        if (!rit) {
          return;
        }
        if(this.checkPast(this.activeItem.datum)){
          alert('De datum die u selecteerde is reeds opgenomen in de planning en dus niet meer te wijzigen.\nNeem contact op met de dispatch indien u toch nog een wijziging wenst door te voeren.');
          return;
        }
        this.isLoading = true;
        try {
          await this.$store.dispatch('planner/cancelOrder', {id: rit.id});
          this.activeItem = null;
          this.loadCalendarData(this.calendarData.meta.current_month, this.calendarData.meta.current_year);
        } catch (error) {
          this.error = error;
          this.isLoading = false;
        }
      } else {
        const promises = [];
        this.activeItem.bookings.forEach(rit => {
          promises.push(this.$store.dispatch('planner/cancelOrder', {id: rit.id}));
        });
        this.isLoading = true;
        try {
          await Promise.all(promises);
          this.activeItem = null;
          this.loadCalendarData(this.calendarData.meta.current_month, this.calendarData.meta.current_year);
        } catch (e) {
          this.error = e;
          this.isLoading = false;
        }
      }
    },

    formatDateArraySql(array) {
      const sqlArray = [];
      array.forEach(d => {
        const parts = d.split('/');
        sqlArray.push(`${parts[2]}/${parts[1]}/${parts[0]}`);
      });
      return sqlArray;
    },

    addMulti(item) {
      if (item.bookings.length || this.checkPast(item.datum)) {
        return;
      }
      const id = this.multiArray.indexOf(item.datum);
      if (id > -1) {
        this.multiArray.splice(id, 1);
      } else {
        this.multiArray.push(item.datum);
      }
      if (!this.order) {
        this.order = {
          richting: "HT",
          datums: this.multiArray,
          location_id_heen: this.userData.location_id,
          location_id_terug: this.userData.location_id,
          corp_id_heen: this.userData.corp_id,
          corp_id_terug: this.userData.corp_id,
          uurHeen: this.userData.uurHeen,
          uurTerug: this.userData.uurTerug,
          user_id: this.userData.id
        }
      } else {
        this.order.datums = this.multiArray;
      }
    },
    checkReadOnly(bookings) {
      const hasH = bookings.find(i => i.richting === "H");
      const hasT = bookings.find(i => i.richting === "T");
      if (hasH && hasT) {
        return hasT.read_only && hasH.read_only;
      } else if (hasT) {
        return hasT.read_only;
      } else if (hasH) {
        return hasH.read_only;
      }
    },
    showMap(type){
      const isCorp = type.match('corp');
        this.mapData = {
          type,
          locations: isCorp?this.corporateLocations:this.pickupLocations,
          selected: this.order[type]
        }
    },
    setDefaultMarker(id){
      const type = this.mapData.type;
      this.order[type] = id;
      this.mapData = null;
    },

    prevMonth(){
      console.log(this.currentMonth);
      if(this.currentMonth === 1){
        this.currentYear--;
        this.currentMonth = 12;
        return;
      }
      this.currentMonth--;
    },
    nextMonth(){
      console.log(this.currentMonth);
      if(this.currentMonth === 12){
        this.currentYear++;
        this.currentMonth = 1;
        return;
      }
      this.currentMonth++;
    },

    visibilityChanged(){
      if (document.visibilityState === 'visible') {
        console.log('planner visibility state changed.');
        this.isMultiSelect = false;
        this.clearActive();
        this.getToday();
        this.loadCalendarData();
      }
    }
  }

}
</script>