<template>
<div class="login">
  <div class="login__content">
    <img src="../assets/image/logo-billo-work.svg" alt="logo Billo 2 work">
    <h1>Nieuw wachtwoord</h1>
    <form action="" class="form" @submit.prevent="submitForm" v-if="validRequest">
      <label for="password">Wachtwoord</label>
      <input class="input input--login" placeholder="wachtwoord" type="password" id="password"
             v-model.trim="password"/>
      <label for="password2">Bevestig wachtwoord (min 8 karakters)</label>
      <input class="input input--login" placeholder="wachtwoord" type="password" id="password2"
             v-model.trim="password2"/>
      <button class="button button--accept">Verstuur aanvraag</button>
    </form>
    <p v-else>Deze link is ongeldig of is verlopen, terug naar <router-link to="login">"Log in"</router-link></p>
    <p class="error" v-if="!formIsValid">invalid input</p>
    <p class="error" v-if="error">{{error}}</p>
  </div>
</div>
</template>

<script>
export default {
name: "PasswordReset",
  data() {
    return {
      validRequest: true,
      error: null,
      password: "",
      password2: "",
      formIsValid: true
    };
  },
  created(){
    if(!this.$route.query.code || ! this.$route.query.username){
      this.$router.replace('/login');
      return;
    }
    this.checkRequest();
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    }
  },
  watch: {
    isAuthenticated(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace('/');
      }
    }
  },
  methods:{
    async checkRequest(){
      try{
        await this.$store.dispatch('checkPasswordResetValid',{username:this.$route.query.username, code:this.$route.query.code});
        this.validRequest = true;
      } catch (e){
        this.validRequest = false;
      }
    },
    async submitForm(){
      this.formIsValid = this.password && this.password.length >=0 && this.password === this.password2;
      if(!this.formIsValid){
        return;
      }
      try{
        await this.$store.dispatch('editPassword',{username:this.$route.query.username,password:this.password,code:this.$route.query.code});
      } catch (error){
        this.error = error;
      }
    }
  }
}
</script>