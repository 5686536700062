<template>
<div class="login">
  <div class="login__content">
    <img src="../assets/image/logo-billo-work.svg" alt="logo Billo 2 work">
    <h1>Wachtwoord vergeten</h1>
    <p class="success" v-if="isSent">Aanvraag verstuurd, check uw mailbox.</p>
    <form action="" class="form" @submit.prevent="submitForm" v-else>
      <label for="username">Gebruikersnaam</label>
      <input class="input input--login" placeholder="Gebruikersnaam (email)" type="text" id="username"
             v-model.trim="username"/>
      <button class="button button--accept">Verstuur aanvraag</button>
    </form>
    <p class="error" v-if="!formIsValid">invalid input</p>
    <p class="error" v-if="error">{{error}}</p>
  </div>
</div>
</template>

<script>
export default {
name: "PasswordRequest",
  data() {
    return {
      error: null,
      username: "",
      formIsValid: true,
      isSent: false
    };
  },
  methods: {
    async submitForm() {
      this.error = null;
      this.formIsValid = true;
      if(!this.username){
        this.formIsValid = false;
        return;
      }
      try {
        await this.$store.dispatch('resetPasswordUsername', this.username);
        this.isSent = true;
      } catch(e){
        this.error = e;
      }
    }
  }
}
</script>