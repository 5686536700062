export default {
    pickupLocations(state) {
        return state.pickupLocations;
    },

    hasPickupLocations(state) {
        return state.pickupLocations && state.pickupLocations.length;
    },

    corporateLocations(state) {
        return state.corporateLocations;
    },

    hasCorporateLocations(state) {
        return state.corporateLocations && state.corporateLocations.length;
    }
};