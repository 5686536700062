export default {
    userData(state) {
        return state.userData;
    },
    companyData(state){
        return state.companyData;
    },
    calendarData(state){
        return state.calendarData;
    }
};