export default {
    async loadLocations(context) {
        const token = context.rootGetters.token;
        const userId = context.rootGetters.userId;
        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/locations/listlocations?user_id=${userId}`;
        const response = await fetch(url,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                }
            });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to fetch user locations.'
            );
            throw error;
        }
        const locations = responseData.data;
        let pickupLocations = [...locations].filter(l=>l.soort === 'pickup');
        let corporateLocations = [...locations].filter(l=>l.soort === 'corporate');


        context.commit('setLocations',{pickupLocations, corporateLocations});
    },

    async addLocation(context, data) {
        const token = context.rootGetters.token;
        const userId = context.rootGetters.userId;

        data.user_id=userId;
        data.tarief=""; //todo tarief invullen

        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/locations/addlocation`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to save new location.'
            );
            throw error;
        }
    },

    async editLocation(context,data) {
        const token = context.rootGetters.token;

        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/locations/editlocation`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to edit location.'
            );
            throw error;
        }
    },

    async deleteLocation(context, data) {
        const token = context.rootGetters.token;

        const url = `${process.env.VUE_APP_ROOT_API}${process.env.VUE_APP_ROOT_API_ENV}/locations/dellocation`;
        const response = await fetch(url,{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        if (!response.ok || !responseData.status) {
            const error = new Error(
                responseData.error_description || 'Failed to delete location.'
            );
            throw error;
        }
    }
}