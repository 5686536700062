<template>
  <TheHeader v-if="isAuthenticated" @on-van-clicked="showNextTrip"></TheHeader>
  <router-view/>
  <TheFooter v-if="isAuthenticated"></TheFooter>
  <div class="update" v-if="updateExists">
    <span>Update beschikbaar</span>
    <button class="button" @click="refreshApp">Update</button>
  </div>
  <teleport to="#app">
    <div class="modal vaninfo" v-if="nexttrip">
      <div class="modal__wrapper">
        <button class="svgbutton modal__close error" @click="nexttrip=null">
          <fa icon="times"></fa>
        </button>
        <template v-if="nexttrip.no_info">
          <div class="modal__title">Mijn bus</div>
          <div class="modal__body">
            <p>Er zijn nog geen gegevens beschikbaar voor uw eerstvolgende rit.</p>
          </div>
        </template>
        <template v-else>
          <div class="modal__title">Mijn bus</div>
          <div class="modal__body">

            <p class="vaninfo__busnr" v-if="nexttrip.idwagen && nexttrip.idwagen != '0'">
              {{nexttrip.idwagen}}
            </p>
            <p v-else>Nog geen bus toegewezen.</p>
            <p class="vaninfo__time">{{nexttrip.displaydatum}} - {{nexttrip.uur}}</p>
            <div class="vaninfo__traject">
              <p>{{nexttrip.van.naam}}</p>
              <fa class="vaninfo__traject__arrow" icon="arrow-down"></fa>
              <p>{{nexttrip.naar.naam}}</p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </teleport>
</template>
<script>

import update from "@/mixins/update";
import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";

export default {
  components: {
    TheHeader,
    TheFooter
  },
  data() {
    return {
      nexttrip: null
    };
  },
  created(){
    console.log('created app');
    document.addEventListener('visibilitychange', this.visibilityChanged);
  },

  mixins: [update],
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    pLocations() {
      return this.$store.getters['locations/pickupLocations'];
    },
    cLocations() {
      return this.$store.getters['locations/corporateLocations'];
    },
  },

  methods: {
    async showNextTrip() {
      try {
        this.nexttrip = await this.$store.dispatch('planner/nextTrip');
        const r = this.nexttrip.richting;
        const ploc = this.pLocations.find(l => l.id == this.nexttrip.billo2work_loc_id);
        const cloc = this.cLocations.find(l => l.id == this.nexttrip.billo2work_corp_id);
        this.nexttrip.van =  r ==='H'? ploc:cloc;
        this.nexttrip.naar =  r ==='H'? cloc:ploc;
      } catch (e) {
        this.nexttrip = {no_info: true};
      }
    },
    visibilityChanged(){
      if (document.visibilityState === 'visible') {
        console.log('app visibility state changed.');
        if(this.nexttrip){
          console.log('reloading next trip information.');
          this.showNextTrip();
        }
      }
    }
  }
}
</script>
<style lang="scss">
@import 'assets/scss/app';
</style>