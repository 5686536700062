<template>
  <div class="select-map"></div>
</template>

<script>
export default {
  name: "SelectMap",
  props: {
    data: {required: true},
    selected: {}
  },

  data() {
    return {
      map: null,
      markers: [],
      openwin: []
    };
  },

  mounted() {
    window.linkToKey = this.linkToKey;
    this.initMap();
  },

  beforeUnmount() {
    this.markers.forEach(m => {
      m.setMap(null);
      m = null;
    });
    window.linkToKey = null;
  },

  methods: {
    linkToKey(id){
      this.$emit('on-id-chosen',id);
    },
    initMap() {
      this.map = new google.maps.Map(this.$el, {
        center: {lat: 51.21848884674257, lng: 4.4053227982318175},
        zoom: 10,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });

      this.moveToCurrentLocation();
      this.drawAllMarkers();
    },
    moveToCurrentLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const devCenter = new google.maps.LatLng(lat, lng);
          this.map.setCenter(devCenter);
        });
      }
    },

    clearMarkers() {
      this.closeWindows();
      this.markers.forEach(m => {
        m.setVisible(false)
      });
    },

    drawAllMarkers() {
      const locs = this.data;
      const svgMarker = {
        path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
        fillColor: "#FAD701",
        strokeColor: "black",
        fillOpacity: 0.8,
        strokeWeight: 2,
        rotation: 0,
        scale: 0.8
      };
      const selectMarker = {
        path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
        fillColor: "red",
        strokeColor: "red",
        fillOpacity: 0.8,
        strokeWeight: 2,
        rotation: 0,
        scale: 0.8
      }
      locs.forEach(l => {
        const latlng = {lat: parseFloat(l.lat), lng: parseFloat(l.lon)};
        const marker = new google.maps.Marker({
          id: l.id,
          position: latlng,
          title: l.naam,
          icon: this.selected == l.id ? selectMarker:svgMarker,
          map: this.map
        });
        const contentString = `<p><b>${l.naam}</b></p><p>${l.straat} ${l.nummer}<br>${l.postnr} ${l.stad}</p><p><b>Opstaptijden:</b><br>${l.soort==='pickup'?l.urenHeen:l.urenTerug}</p>${this.selected == l.id?`<p style="color:green">Geselecteerd</p>`:`<button class="button" onclick="linkToKey(${l.id})">Selecteer</button>`}`;
        const infowindow = new google.maps.InfoWindow({
          content: contentString,
        });
        marker.addListener("click", () => {
          infowindow.open(this.map, marker);
          this.openwin.push(infowindow)
        });
        this.markers.push(marker);
      });
    }
  }
}
</script>