<template>
  <footer class="footer">
    <p>Billo2Work | <a target="_blank" href="mailto:info@billo.be">info@billo.be</a> | <a href="tel:+32(9)2531343">+32 (9) 253 13 43</a> | <a href="https://www.privalex.be/nl/bedrijven/8a8078f562" target="_blank">Privacy</a> | versie {{version}}</p>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
  data() {
    return {
      version: process.env.VERSION
    }
  }
}
</script>