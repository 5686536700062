<template>
<header class="header">
  <div class="header__container">
    <div class="header__logo"><img src="../assets/image/logo-billo-work.svg" alt="logo Billo 2 work"></div>
  </div>
  <div class="header__nav">
    <router-link class="svgbutton" to="/planner"><fa icon="calendar-alt"></fa></router-link>
    <button class="svgbutton" @click="$emit('on-van-clicked')"><fa icon="shuttle-van"></fa></button>
    <router-link class="svgbutton" to="/map"><fa icon="map"></fa></router-link>
    <router-link class="svgbutton" to="/settings"><fa icon="user"></fa></router-link>
    <a target="_blank" v-if="userData" :href="`/handleiding/billo2work-users-${userData.user_id}.pdf`" class="svgbutton"><fa icon="question-circle"></fa></a>
  </div>
</header>
</template>

<script>
export default {
name: "TheHeader",

  computed:{
    userData() {
      return this.$store.getters['planner/userData'];
    },
  }
}
</script>