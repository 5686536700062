<template>
  <div class="page settings">
    <h1>Beheer uw voorkeuren</h1>
    <p class="settings__current">Ingelogd als <b>{{userData.email}}</b></p><button class="button button--decline logout" @click="logout">Uitloggen</button>
    <p>Wijzig hier uw contactgegevens en de standaardinstellingen voor uw planner.</p>
    <form class="form" action="" @submit.prevent="submitForm">
      <div class="form__column">
        <div class="form__field">
          <label for="fname">Voornaam</label>
          <input id="fname" type="text" class="input" v-model="editUserData.firstname">
        </div>
        <div class="form__field">
          <label for="name">Naam</label>
          <input id="name" type="text" class="input" v-model="editUserData.lastname">
        </div>
        <div class="form__field">
          <label for="email">Email</label>
          <input id="email" type="email" class="input" v-model="editUserData.email">
        </div>
        <div class="form__field">
          <label for="tel">Tel/GSM</label>
          <input id="tel" type="tel" class="input" v-model="editUserData.tel">
        </div>
      </div>
      <div class="form__column" v-if="companyData.settings.employeeaddresses">
        <div class="form__field">
          <label for="street">Straat</label>
          <input id="street" type="text" class="input" v-model="editUserData.street">
        </div>
        <div class="form__field">
          <label for="nr">Nr</label>
          <input id="nr" type="text" class="input" v-model="editUserData.nr">
        </div>
        <div class="form__field">
          <label for="zip">Postcode</label>
          <input id="zip" type="text" class="input" v-model="editUserData.zip">
        </div>
        <div class="form__field">
          <label for="city">Gemeente</label>
          <input id="city" type="text" class="input" v-model="editUserData.city">
        </div>
        <div class="form__field">
          <label for="country">Land</label>
          <select name="country" id="country" class="select" v-model="editUserData.country">
            <option value="BE">Belgium</option>
            <option value="FR">France</option>
            <option value="NL">Netherlands</option>
            <option value="LU">Luxembourg</option>
            <option value="DE">Germany</option>
          </select>
        </div>
      </div>
      <div class="form__column">
        <div class="form__field">
          <label for="ploc">Default ophaalpunt</label>
          <div>
            <select name="ploc" id="ploc" class="select" v-model="editUserData.location_id">
              <option v-for="item in pickupLocations" :value="item.id" :key="`ploc--${item.id}`">{{item.naam}} -- {{
                  item.stad
                }}
              </option>
            </select>
            <button class="svgbutton" @click.prevent="showMap('pickup')"><fa icon="map"></fa></button>
          </div>
        </div>
        <div class="form__field">
          <label for="cloc">Default bedrijfslocatie</label>
          <div>
          <select name="cloc" id="cloc" class="select" v-model="editUserData.corp_id">
            <option v-for="item in corporateLocations" :value="item.id" :key="`cloc--${item.id}`">{{
                item.naam
              }}
            </option>
          </select>
            <button class="svgbutton" @click.prevent="showMap('corp')"><fa icon="map"></fa></button>
          </div>
        </div>
        <div class="form__field">
          <label for="ptime">Default ophaaltijd HEEN</label>
          <select name="ptime" id="ptime" class="select" v-model="editUserData.uurHeen">
            <option v-for="item in pickupTimes" :value="item" :key="`ptime--${item}`">{{ item }}</option>
          </select>
        </div>
        <div class="form__field">
          <label for="ctime">Default vertrektijd TERUG</label>
          <select name="ctime" id="ctime" class="select" v-model="editUserData.uurTerug">
            <option v-for="item in dropoffTimes" :value="item" :key="`ctime--${item}`">{{ item }}</option>
          </select>
        </div>
      </div>
      <div class="form__column">
        <div class="form__field">
          <label for="company">Bedrijf facturatie</label>
          <input id="company" type="text" class="input" v-model="editUserData.invoicecompany" readonly>
        </div>
        <div class="form__field">
          <label for="ref">Ref</label>
          <input id="ref" type="text" class="input" v-model="editUserData.ext_ref" readonly>
        </div>
      </div>
    </form>
    <p class="error" v-if="error">{{ error }}</p>
    <p class="success" v-if="success">Wijzigingen opgeslagen.</p>
    <div class="settings__controls" v-if="editUserData">
      <button class="button" :class="{'button--disabled':isLoading}" @click.prevent="resetUser">Reset wijzigingen</button>
      <button class="button button--accept" :class="{'button--disabled':isLoading}" @click.prevent="submitForm">Opslaan</button>
    </div>
    <teleport to="#app">
      <div class="modal" v-if="mapData">
        <div class="modal__wrapper">
          <button class="svgbutton modal__close error" @click="mapData=null">
            <fa icon="times"></fa>
          </button>
          <div class="modal__body">
            <SelectMap :data="mapData.locations" :selected="mapData.selected" @on-id-chosen="setDefaultMarker"/>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import SelectMap from "@/components/SelectMap";
export default {
  name: "Settings",
  components: {SelectMap},
  data() {
    return {
      error: null,
      success: false,
      editUserData: null,
      isLoading: false,
      mapData:null,
    }
  },
  created() {
    this.editUserData = {...this.userData};
  },
  computed: {
    pickupLocations() {
      const locs = [...this.$store.getters['locations/pickupLocations']];
      return locs.sort((a,b) => (a.stad.toLowerCase() > b.stad.toLowerCase()) ? 1 : ((b.stad.toLowerCase() > a.stad.toLowerCase()) ? -1 : 0));
    },
    corporateLocations() {
      return this.$store.getters['locations/corporateLocations'];
    },
    userData() {
      return this.$store.getters['planner/userData'];
    },
    companyData() {
      return this.$store.getters['planner/companyData'];
    },
    pickupTimes() {
      if (!this.editUserData) {
        return [];
      }
      const p = this.pickupLocations.find(l => parseInt(l.id) === parseInt(this.editUserData.location_id));
      return p ? p.urenHeen : [];
    },
    dropoffTimes() {
      if (!this.editUserData) {
        return [];
      }
      const d = this.corporateLocations.find(l => parseInt(l.id) === parseInt(this.editUserData.corp_id));

      const p = this.pickupLocations.find(l => parseInt(l.id) === parseInt(this.editUserData.location_id));
      return d && p ? d.urenTerug.filter(u => p.urenTerug.indexOf(u)>-1) : [];
    }
  },
  watch: {
    userData() {
      this.editUserData = {...this.userData};
    },
    pickupTimes() {
      if (this.pickupTimes && this.editUserData && this.pickupTimes.indexOf(this.editUserData.uurHeen) < 0) {
        this.editUserData.uurHeen = this.pickupTimes[0];
      }
    },
    dropoffTimes() {
      if (this.dropoffTimes && this.editUserData && this.dropoffTimes.indexOf(this.editUserData.uurTerug) < 0) {
        this.editUserData.uurTerug = this.dropoffTimes[0];
      }
    }
  },
  methods: {
    setDefaultMarker(id){
      const type = this.mapData.type;
      if(type === 'pickup'){
        this.editUserData.location_id = id;
      }
      else {
        this.editUserData.corp_id = id;
      }
      this.mapData = null;
    },
    showMap(type){
      if(type === 'pickup'){
        this.mapData = {
          type,
          locations: this.pickupLocations,
          selected: this.editUserData.location_id
        }
      }else{
        this.mapData = {
          type,
          locations: this.corporateLocations,
          selected: this.editUserData.corp_id
        }
      }
    },
    async submitForm() {
      this.error = null;
      this.success = false
      if (Object.keys(this.editUserData).some(key => (key!=='tel' && key !== 'street' && key !== 'nr' && key !== 'zip' && key !== 'country' && key !== 'city' && key !== 'lat' && key !== 'lon') && !this.editUserData[key])) {
        this.error = 'incomplete data';
        return;
      }
      this.isLoading = true;
      try {
        await this.$store.dispatch('planner/editUserData', this.editUserData);
        this.success = true;
      } catch (error) {
        this.error = error;
      }
      this.isLoading = false;
    },
    resetUser() {
      this.editUserData = {...this.userData};
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.replace('/login');
    }
  }
}
</script>