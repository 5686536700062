import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store';
import Login from "@/views/Login";
import PasswordRequest from "@/views/PasswordRequest";
import PasswordReset from "@/views/PasswordReset";
import Planner from "@/views/Planner";
import Settings from "@/views/Settings";
import Map from "@/views/Map";

const routes = [
  {
    path: '/',
    redirect: '/planner',
  },
  {
    path: '/planner',
    name: 'Planner',
    component: Planner,
    meta: {requiresAuth: true}
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {requiresAuth: true}
  },
  {
    path: '/map',
    name: 'Map',
    component: Map,
    meta: {requiresAuth: true}
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {requiresUnauth: true}
  },
  {
    path: '/password-request',
    name: 'PasswordRequest',
    component: PasswordRequest,
    meta: {requiresUnauth: true}
  },
  {
    path: '/reset-password',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: {requiresUnauth: true}
  },
  {
    path: '/:notFound(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach(function (to, _, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next('/login');
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router
