<template>
  <div class="login">
    <div class="login__content">
      <img src="../assets/image/logo-billo-work.svg" alt="logo Billo 2 work">
      <h1>Login</h1>
      <form action="" class="form" @submit.prevent="submitForm">
        <label for="username">Gebruikersnaam</label>
        <input id="username" type="text" class="input" v-model.trim="username">
        <label for="password">Wachtwoord</label>
        <input id="password" type="password" class="input" v-model.trim="password">
        <button class="button button--accept">Log in</button> <router-link to="/password-request">Wachtwoord vergeten?</router-link>
      </form>
      <p class="error" v-if="!formIsValid">invald input</p>
      <p class="error" v-if="error">{{error}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data(){
    return {
      username: "",
      password: "",
      formIsValid: true,
      isLoading: false,
      error: null
    };
  },

  created() {
    this.$store.dispatch('tryLogin');
  },

  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    }
  },

  watch: {
    isAuthenticated(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        if (this.$route.query.nextUrl) {
          this.$router.replace(this.$route.query.nextUrl);
          return;
        }
        this.$router.replace('/');
      }
    }
  },

  methods: {
    async submitForm() {
      this.error = null;
      this.formIsValid = true;
      if(!this.username || this.password.length < 4){
        this.formIsValid = false;
        return;
      }
      this.isLoading = true;
      const payload = {
        username: this.username,
        password: this.password
      };
      try{
        await this.$store.dispatch('login', payload);
      }
      catch (err){
        this.error = err.message || "error";
      }
    }
  }
}
</script>