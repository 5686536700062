import { createStore } from 'vuex'
import authModule from './modules/auth';
import locationsModule from './modules/locations';
import plannerModule from './modules/planner';

export default createStore({
  modules: {
    auth: authModule,
    locations: locationsModule,
    planner: plannerModule
  }
})
